<template>
  <div>
    <!-- Filter -->
    <div :class="`row align-items-center justify-content-between ${
        purpose == 'patient-history' ? 'mt-3' : ''
      }`">
      <div :class="hasPatient ? 'col-md-9' : 'col-md-6'">
        <span class="d-none d-sm-block">
          <b-input-group>
            <template #prepend>
              <b-button
                squared
                @click="resetFilterPeriode"
                variant="danger"
              >Reset</b-button>
              <b-button
                squared
                @click="filterByPeriode"
                variant="success"
              >Cari</b-button>
            </template>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button
              squared
              variant="light"
            >s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <template #prepend>
                  <b-button
                    squared
                    @click="resetFilterPeriode"
                    variant="danger"
                  >Reset</b-button>
                  <b-button
                    squared
                    @click="filterByPeriode"
                    variant="success"
                  >Cari</b-button>
                </template>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button
                  squared
                  variant="light"
                >s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
      <div
        class="col-md-3 col-6"
        v-if="currentUser.user_type_id != 1"
      >
        <treeselect
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          placeholder="Cari Berdasar Dokter"
          @select="filterByDoctor"
        />
      </div>
      <div
        class="col-md-3 col-6"
        v-if="purpose == 'master'"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Filter -->
    <b-table
      striped
      hover
      responsive
      style="white-space: nowrap"
      class="mt-3"
      :items="items"
      :fields="fields"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width:
              purpose == 'master'
                ? field.key === 'actions'
                  ? '25%'
                  : ''
                : field.key === 'actions'
                ? '15%'
                : '',
          }"
        />
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-warning"
            @click="
              $router.push({ path: '/lab-report/attachment/' + data.item.id })
            "
          ><i class="far fa-file px-0"></i></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="
              $router.push({ path: '/lab-report/detail/' + data.item.id })
            "
          ><i class="fas fa-eye px-0"></i></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-if="manipulateBtn == true"
            @click="$router.push({ path: '/lab-report/edit/' + data.item.id })"
          ><i class="fas fa-edit px-0"></i></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-if="manipulateBtn == true"
            @click="btnDeleteOnClick(data.item.id)"
          ><i class="fas fa-trash px-0"></i></b-button>
        </template>
        <template v-if="purpose === 'patient-history'">
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-modal.modal-print-lab
            @click="btnDetailOnClick(data.item.id)"
          ><i class="fas fa-eye px-0"></i></b-button>
          <b-button
            size="sm"
            class="btn-info"
            v-b-modal.modal-print-lab
            @click="btnDownloadOnClick(data.item.id)"
          ><i class="fas fa-download px-0"></i></b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    items: Array,
    fields: Array,
    purpose: String,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
    hasPatient: Boolean,
  },

  components: {
    Treeselect,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Options
      doctors: [],
      manipulateBtn: false,
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.$emit("pageOnClick", page);
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async filterByDoctor(evt) {
      await this.filter.doctor_id;
      this.$emit("filter", this.filter);
    },

    resetFilterPeriode() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.filter.patient_name = "";
      if (this.currentUser.user_type_id != 1) {
        this.filter.doctor_id = "";
      }
      this.$emit("filter", this.filter);
    },

    filterByPeriode() {
      this.$emit("filter", this.filter);
    },

    async filterByPatient() {
      if (this.currentUser.user_type_id != 1) {
        this.filter.doctor_id = "";
      }
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.$emit("filterByPatient", this.filter);
    },

    btnDeleteOnClick(id) {
      this.$emit("btnDeleteOnClick", $id);
    },

    btnDownloadOnClick(id) {
      this.$emit("btnDownloadOnClick", id);
    },

    btnDetailOnClick(id) {
      this.$emit("btnDetailOnClick", id);
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "1102") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    if (this.currentUser.user_type_id != 1) {
      // Get Options
      this.getDoctorsOption();
    }
    this.setActiveMenu();
  },
};
</script>

<style>
</style>